import { Component, OnInit } from '@angular/core';


@Component({
	selector: 'breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
	
	constructor(
	){}

	ngOnInit() {
		
	}
	
	

}
