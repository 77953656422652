// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_url: "https://demostore.successlife.com",
  api: {
    host: "https://api.demostore.successlife.com",
    port: "3000",
  },
  //api_url: 'https://api.demostore.successlife.com/api', //http://192.168.1.30:3000/api, https://api.demomarketplace.successlife.com/api
  // api_url:'https://api.store.successlife.com/api',
   //api_url: "http://localhost:3000/api",
  api_url:'https://pay.successlife.com/api',
  //api_url:'http://localhost:3000/api',

  //api_url:"http://139.59.1.244:3000/api",
  noImage: {
    size75x30: "assets/images/noimg-75x60.jpg",
    size98x138: "assets/images/noimg-98x138.jpg",
    size125x105: "assets/images/noimg-125x105.jpg",
    size222x150: "assets/images/noimg-222x150.jpg",
    size290x195: "assets/images/noimg-290x195.png",
    size356x221: "assets/images/noimg-356x221.png",
    size387x400: "assets/images/noimg-387x400.jpg",
    size400x400: "assets/images/noimg_400_400.jpg",
    size530x345: "assets/images/noimg-530x345.jpg",
    size1900x500: "assets/images/noimg-1900x500.jpg",
  },
  paypal_url: "https://ipnpb.paypal.com/cgi-bin/webscr",
  paypalCredentials: {
    sandbox: {
      url: "https://api.sandbox.paypal.com/v1",
      appId: "APP-80W284485P519543T",
      clientId:
        "AcdWu8o0oCQEXWS8RMJLilp_BvvbsAJbM5rlbXk8WIQY0URd42aawKSAiy3eg1kRQYYSrp_vKmioEu7g",
      secret:
        "EKcJwUyaxlPpiWILeYbXBl5nAhhw6jfkK-do6nH_G7X6tuPyw_Qyi5oX90F-WWZTtV1Ke3mSOt3N371U",
    },
  },
  sxlCredential: {
    url: "http://sxlstaging.localbtcmarket.com/api/ethereum-php",
  },
  orderIdPrefix: "#SLM100",
  vendorIdPrefix: "#SLMV100",
  custmerIdPrefix: "#SLMC100",
  resellerIdPrefix: "#SLMR100",
  eventIdPrefix: "#SLME100",
  kycUrl: "https://vault.successlife.com",
  kycReferenceVideoUrl: "https://youtu.be/EzN5h3Sqb8k",
  courseRefundDuration: 5,
  idleDurationSeconds: 3600,
  bannerSliderDurationSeconds: 3000,
  courseDefaultPaymentTypeId: 3,
  courseDefaultPaymentCategoryId: 1,
  numberOfChapterInCourseDetails: 5,
  courseUsdSxlRatio: 0.5,
  ticketBookingTimeDiff: 3,
  metaTitel: "SuccessLife",
  metaDescription:
    "SuccessLife is the world&#39;s largest destination for online courses. Discover an online course on SuccessLife.com and start learning a new skill today.",
  metaKeywords: "",
  encrDecrKey: "#SLM2019Matrix",
  address:
    " SuccessLife Global Pte. Ltd. <br> 190 Macpherson Road, #08-01, Wisma Gulab <br> Singapore 348548<br>",
  phone_number: " +65 6299 4677",
  mail_address: "support@successlife.com",
  // s3Credentials: {
  //   bucket: "marketplace.successlife.com",
  //   credentials: {
  //     accessKeyId: "AKIAJLXEA5OXRL655N6A",
  //     secretAccessKey: "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",
  //     region: "ap-southeast-1",
  //     ACL: "public-read",
  //   },
  // },
 s3Credentials: {
    bucket: "testbucket3334",
    credentials: {
      accessKeyId: "AKIA4DIN2TX6XPKPEALX",
      secretAccessKey: "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",
      region: "us-east-1",
      ACL: "public-read",
    },
  },
  
  notificationConfig: {
    buttonsStyling: false,
    customClass: "modal-content",
    confirmButtonClass: "btn btn-success btn-alert",
    cancelButtonClass: "btn btn-danger btn-alert",
    showCancelButton: true,
    confirmButtonText: '<i class="icon ion-trash-b"></i> Confirm',
    cancelButtonText: '<i class="icon ion-close"></i> Cancel',
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    focusConfirm: false,
  },
  GoogleProviderId:
    "192299981489-n3fed48m59b5l3lqtl2i6nue0aghdrbg.apps.googleusercontent.com",
  FaceBookProviderId: "279399912750699",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
